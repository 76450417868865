var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    { staticClass: "mt-5 px-md-12 px-sm-5" },
    [
      _c("app-form", {
        ref: "form",
        attrs: {
          fields: _vm.fields,
          submitButton: _vm.submitButton,
          footerClass: "col-12 text-center",
          disableCancelButton: ""
        },
        on: { submitted: _vm.handleSubmit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }